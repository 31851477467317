import { z } from 'zod'
import { locationSchema } from './useLocations.types'

export enum AuthenticationProvider {
  Email = 'email',
  Azure = 'azure',
}

export enum Feature {
  Footprint = 'footprint',
  Reduction = 'reduction',
  Reporting = 'reporting',
  IsolatedChildren = 'isolated_children',
  CreateCompany = 'create_company',
}

/**
 * See `app/models/concerns/has_onboarding_state_machine.rb` for reference
 */
export enum OnboardingStatus {
  // TODO: rename to "companySetup" https://github.com/GlobalChanger/global_changer_web/pull/1797#discussion_r1383102919
  NotStarted = 'not_started',
  CategoriesCustomization = 'categories_customization',
  QuickstartPrompt = 'quickstart_prompt',
  Finished = 'finished',
}

export const companySchema = z.strictObject({
  authenticationProvider: z.nativeEnum(AuthenticationProvider).nullable(),
  baseYear: z.number().nullable(),
  industry: z.string().nullable(),
  createdAt: z.string(),
  discountRate: z.number().nullable(),
  hasCurrentUserWriteAccess: z.boolean(),
  hasLinearReduction: z.boolean(),
  hasResetOnboarding: z.boolean(),
  features: z.nativeEnum(Feature).array(),
  id: z.number(),
  linearReduction: z.number().nullable(),
  mainLocation: locationSchema.nullable(),
  name: z.string().nullable(),
  netZeroYear: z.number().nullable(),
  onboardingStatus: z.nativeEnum(OnboardingStatus),
  depth: z.number(),
})

export type Company = z.infer<typeof companySchema>

export const companyMutationDataSchema = companySchema
  .partial()
  .pick({
    name: true,
    baseYear: true,
    discountRate: true,
    hasLinearReduction: true,
    linearReduction: true,
    netZeroYear: true,
    industry: true,
  })
  .extend({
    mainLocationAttributes: locationSchema.partial().nullish(),
  })
  .strip()

export type CompanyMutationData = z.infer<typeof companyMutationDataSchema>

export const newCompanyDataSchema = z.strictObject({
  parentId: z.number(),
  mainLocationAttributes: locationSchema.pick({ name: true, country: true }),
})

export type NewCompanyData = z.infer<typeof newCompanyDataSchema>

export interface CompanyTreeFilterParams {
  includeCurrentCompany?: boolean
  includeChildCompanies?: boolean
  includeDescendantCompanies?: boolean
  includeAncestors?: boolean
  includeAllCompanies?: boolean
}
